var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-page"},[_c('el-main',{staticClass:"context-panel",class:[_vm.storeAdmin.role !== 'user' ? 'user-height-content' : '']},[_c('span',{staticClass:"panel-title"},[_vm._v("基本信息")]),_c('table',{staticClass:"user-profile-table",attrs:{"border":"0"}},[_c('tr',[_c('td',[_vm._v("用户名")]),(_vm.storeAdmin.role === 'user')?_c('td',[_c('EditItem',{attrs:{"value":_vm.cloneDeep(_vm.userForm),"block":"","submit-callback":_vm.handleUserNameChange},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('Empty',{attrs:{"value":scope.username}})]}},{key:"edit",fn:function(scope){return [_c('Form',{ref:"usernameFormRef",attrs:{"form-model":scope.value,"labels":{ username: '用户名', old_pwd: '密码' }}},[_c('FormItem',{attrs:{"prop":"username","label":"用户名","required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"value":scope.value.username},on:{"input":function (v) {
                        scope.value.username = v
                        _vm.$refs.usernameFormRef.validateItem('username')
                      }}})],1),_c('FormItem',{attrs:{"prop":"old_pwd","label":"登录密码","rule":_vm.pwdRules,"required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"value":scope.value.old_pwd,"type":"password"},on:{"input":function (v) {
                        scope.value.old_pwd = v
                        _vm.$refs.usernameFormRef.validateItem('old_pwd')
                      }}})],1)],1)]}}],null,false,2579635439)})],1):_c('td',[_vm._v(" "+_vm._s(_vm.storeAdmin.username)+" ")])]),_c('tr',[_c('td',[_vm._v("授权时间")]),_c('td',[_c('Empty',{attrs:{"value":_vm.storeAdmin.not_valid_after}},[(_vm.storeAdmin.not_valid_after == -1 && _vm.storeAdmin.not_valid_before == -1)?_c('span',[_vm._v("永久有效")]):_c('span',{staticStyle:{"font-family":"Monaco"}},[_vm._v(_vm._s(((_vm.formateTime(_vm.storeAdmin.not_valid_before, 'YYYY-MM-DD')) + " - " + (_vm.formateTime(_vm.storeAdmin.not_valid_after, 'YYYY-MM-DD')))))])])],1)]),_c('tr',[_c('td',[_vm._v("邮箱")]),_c('td',[_c('EditItem',{attrs:{"value":_vm.cloneDeep(_vm.emailForm),"block":"","submit-callback":_vm.handleEmailChange},scopedSlots:_vm._u([{key:"default",fn:function(value){return [_c('Empty',{attrs:{"value":value.email}})]}},{key:"edit",fn:function(scope){return [_c('Form',{ref:"emailFormRef",attrs:{"form-model":scope.value}},[_c('FormItem',{attrs:{"prop":"email","label":"邮箱","rule":_vm.emailRules,"required":true}},[_c('Button',{staticClass:"inherit-button",staticStyle:{"margin-left":"16px"},attrs:{"small":true},on:{"click":function($event){return _vm.sendCode(scope.value.email)}}},[_vm._v(" "+_vm._s(_vm.disableSendCode ? _vm.countdown + 's' : '获取验证码')+" ")]),_c('Input',{staticStyle:{"width":"232px"},attrs:{"value":scope.value.email,"type":""},on:{"input":function (v) {
                        scope.value.email = v
                        _vm.$refs.emailFormRef.validateItem('email')
                      }}})],1),_c('FormItem',{attrs:{"prop":"code","label":"验证码","rule":_vm.sixCodeRules,"required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"value":scope.value.code},on:{"input":function (v) {
                        scope.value.code = v
                        _vm.$refs.emailFormRef.validateItem('code')
                      }}})],1),_c('FormItem',{attrs:{"prop":"old_pwd","label":"登录密码","rule":_vm.pwdRules,"required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"value":scope.value.old_pwd,"type":"password"},on:{"input":function (v) {
                        scope.value.old_pwd = v
                        _vm.$refs.emailFormRef.validateItem('old_pwd')
                      }}})],1)],1)]}}])})],1)]),_c('tr',[_c('td',[_vm._v("手机号")]),_c('td',[_c('EditItem',{attrs:{"value":_vm.storeAdmin,"block":"","submit-callback":_vm.handlePhoneChange},scopedSlots:_vm._u([{key:"default",fn:function(admin){return [_c('Empty',{attrs:{"value":((_vm.areaCodeMap[admin.area_code]) + " " + (admin.mobile))}})]}},{key:"edit",fn:function(admin){return [_c('Form',{ref:"mobileFormRef",attrs:{"form-model":admin.value}},[_c('FormItem',{attrs:{"prop":"mobile","label":"手机号","rule":_vm.customMobileRules,"required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"value":admin.value.mobile},on:{"input":function (v) {
                        admin.value.mobile = v
                        _vm.$refs.mobileFormRef.validateItem('mobile')
                      }}},[_c('Select',{staticClass:"prepend-select-no-error",attrs:{"slot":"prepend","value":_vm.area_code,"options":[
                        { value: '86', label: '+86' },
                        { value: '852', label: '+852' },
                        { value: '853', label: '+853' } ]},on:{"input":function (v) {
                          _vm.area_code = v
                        }},slot:"prepend"})],1)],1)],1)]}}])})],1)]),_c('tr',[_c('td',[_vm._v("修改密码")]),_c('td',[_c('EditItem',{attrs:{"value":_vm.cloneDeep(_vm.passwordForm),"block":"","submit-callback":_vm.handlePasswordChange},scopedSlots:_vm._u([{key:"edit",fn:function(scope){return [_c('Form',{ref:"pwdFormRef",attrs:{"form-model":scope.value}},[_c('FormItem',{attrs:{"prop":"old_pwd","label":"原始密码","rule":_vm.pwdRules,"required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"value":scope.value.old_pwd,"type":"password"},on:{"input":function (v) {
                        scope.value.old_pwd = v
                        _vm.$refs.pwdFormRef.validateItem('password')
                      }}})],1),_c('FormItem',{attrs:{"prop":"new_pwd","label":"新密码","rule":_vm.pwdRules,"required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"type":"password","value":scope.value.new_pwd},on:{"input":function (v) {
                        scope.value.new_pwd = v
                        _vm.$refs.pwdFormRef.validateItem('password')
                      }}})],1),_c('FormItem',{attrs:{"prop":"ensure_new","label":"确认密码","rule":_vm.ensurePwdRules(scope.value.new_pwd),"required":true}},[_c('Input',{staticStyle:{"width":"232px"},attrs:{"type":"password","value":scope.value.ensure_new},on:{"input":function (v) {
                        scope.value.ensure_new = v
                        _vm.$refs.pwdFormRef.validateItem('password')
                      }}})],1)],1)]}}])},[_vm._v(" ****** ")])],1)]),_c('tr',[_c('td',[_vm._v("注册时间")]),_c('td',[_vm._v(_vm._s(_vm.storeAdmin.date_joined))])]),_c('tr',[_c('td',[_vm._v("最后登录时间")]),_c('td',[_vm._v(_vm._s(_vm.storeAdmin.last_login))])]),_c('tr',[_c('td',[_vm._v("Token")]),_c('td',[_vm._v(" "+_vm._s(_vm.token.key)+" "),_c('i',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.token.key),expression:"token.key",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"chaitin-iconfont icon-details copyclipboard"})])])])]),(_vm.storeAdmin.role === 'user')?_c('el-main',{staticClass:"context-panel flex1"},[_c('div',{staticClass:"main-page p0"},[_c('List',{staticClass:"table-list-scroll height-auto",attrs:{"table-border":true,"hide-selection":true,"value":_vm.storeAdmin.license_list || [],"row-key":function (row) { return row.package_id; },"operations":[
          {
            icon: 'chaitin-iconfont icon-edit',
            callback: _vm.handleRowClick,
            tooltip: '更新授权文件',
          } ]}},[_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"product_name","label":"关联产品"}}),_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"client_name","label":"授权企业"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.client_name)?_c('span',[_vm._v("-")]):[_c('span',[_vm._v(" "+_vm._s(scope.row.client_name)+" ")])]]}}],null,false,2630066747)}),_c('TableColumn',{attrs:{"coefficient":0.8,"prop":"module","label":"授权时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(!scope.row.not_valid_before)?_c('span',[_vm._v("未授权")]):[(scope.row.not_valid_after == -1 && scope.row.not_valid_before == -1)?_c('span',[_vm._v("永久有效")]):_c('div',[_c('span',{staticStyle:{"font-family":"Monaco"}},[_vm._v(_vm._s(_vm.formateTime(scope.row.not_valid_before, 'YYYY-MM-DD'))+" - "+_vm._s(_vm.formateTime(scope.row.not_valid_after, 'YYYY-MM-DD')))]),(scope.row.not_valid_before > _vm.currentTime)?_c('span',{staticClass:"error-color"},[_vm._v("（未到授权日期）")]):_vm._e(),(scope.row.not_valid_after < _vm.currentTime)?_c('span',{staticClass:"error-color"},[_vm._v("（已过期）")]):_vm._e()])]]}}],null,false,573637406)})],1)],1)]):_vm._e(),_c('Dialog',{attrs:{"value":!!_vm.currentLicense.product,"title":("修改授权（所选产品 " + (_vm.currentLicense.product_name) + "）")},on:{"cancel":function () { return (_vm.currentLicense.product = ''); },"ok":_vm.handleUpdateProductOk,"input":function (v) { return (_vm.currentLicense.product = v); }}},[_c('Form',[_c('FormItem',{attrs:{"label":"上传授权文件"}},[_c('Upload',{attrs:{"value":_vm.productFileInfo,"upload-callback":_vm.licenseUpload,"multiple":false,"placeholder":"证书"},on:{"input":_vm.handleUpdateSelectedCert}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }